import { createApp } from 'vue'
import App from './App.vue'
import Vue3Storage, { StorageType } from "vue3-storage";

/** Plugins */
import vuetify from './plugins/vuetify'
import router from './router'
import { loadFonts } from './plugins/webfontloader'
import store from './store'
import './style/main.scss'
import GlobalDialog from "@/components/GlobalDialog.vue";

loadFonts()

const app = createApp(App)
app.use(vuetify)
app.use(router)
app.use(store)
app.use('GlobalDialog', GlobalDialog)
app.use(Vue3Storage, { namespace: "bo_edu", storage: StorageType.Local })
app.mount('#app')

/** External Dependencies */
import * as VueRouter from 'vue-router'
import store from '@/store'

/** Routes */
import adminRoute from '@/router/routes/admin.route'

const routes = [

  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/auth/Login.vue'),
  },

  {
    path: '/logout',
    name: 'Logout',
    component: () => import('@/views/auth/Logout.vue'),
  },
  {
    path: '/plans',
    name: 'Plan',
    component: () => import('@/views/plan/PlanViews.vue'),
  },
  {
    path: '/user',
    name: 'User',
    component: () => import('@/views/user/UserListViews.vue'),
  },

  // {
  //   path: '/recovery',
  //   name: 'Recovery',
  //   component: () => import('@/views/auth/Recovery.vue'),
  // },
  // {
  //   path: '/change_password',
  //   name: 'ChangePassword',
  //   component: () => import('@/views/auth/ChangePassword.vue'),
  // },

  adminRoute,

  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    redirect: '/admin/home'
  },

]

const router = new VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes
})


function isAuthenticated() {
  return store.getters['auth/isAuthenticated'];
}

function inAuthRoutes(to) {
  return ['Login', 'Recovery', 'ChangePassword', 'Logout'].indexOf(to.name) > -1;
}

router.beforeEach((to, from, next) => {
  if (!inAuthRoutes(to) && !isAuthenticated()) {
    next('/login')
  } else if (inAuthRoutes(to) && isAuthenticated()) {
    next('/admin/home')
  } else {
    if (isAuthenticated()) store.dispatch('auth/GetProfile')
    next()
  }
})

export default router

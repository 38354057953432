export default {
  path: '/admin',
  name: 'Admin',
  redirect: '/admin/home',
  component: () => import('@/components/layouts/EPortalLayout'),
  meta: {
    title: 'Dashboard',
    menu: 1,
    disabled: true,
    icon: 'bar-chart-fill'
  },
  children: [
    {
      path: 'home',
      name: 'Home',
      component: () => import('@/views/home/Home')
    },

    {
      path: 'establishments',
      name: 'EstablishmentList',
      component: () => import('@/views/establishment/EstablishmentList')

    },

    {
      path: 'establishmentsForm/:id/:action',
      name: 'EstablishmentForm',
      component: () => import('@/views/establishment/EstablishmentForm.vue'),
      meta: {
        title: ':action de establecimiento :id'
      },
    },


    {
      path: 'suscriptions',
      name: 'Suscription',
      component: () => import('@/views/suscription/SuscriptionsViews')
    },
    {
      path: 'suscriptions/:id/:action',
      name: 'suscriptionForm',
      component: () => import('@/views/suscription/SuscriptionFormViews'),
      meta: {
        title: ':action de Suscripcion :id'
      },
    },

    {
      path: 'plans',
      name: 'Plan',
      component: () => import('@/views/plan/PlanViews')
    },
    {
      path: 'plans/:id/:action',
      name: 'PlanForm',
      component: () => import('@/views/plan/PlanFormViews'),
      meta: {
        title: ':action de Plan :id'
      },
    },

    {
      path: 'funcionality_options',
      name: 'FuncionalityOption',
      component: () => import('@/views/funcionality/FuncionalityOption.vue'),
      meta: {
        title: 'Mantenedor Funcionalidades',
      },
    },
    {
      path: 'funcionality_options/plan_funcionalities',
      name: 'PlanFuncionality',
      component: () => import('@/views/funcionality/PlanFuncionalityList.vue')
    },
    {
      path: 'funcionality_options/plan_funcionalities/:id/:action',
      name: 'PlanFuncionalityForm',
      component: () => import('@/views/funcionality/PlanFuncionalityForm'),
      meta: {
        title: ':action de Funcionality :id'
      },
    },
    {
      path: 'funcionality_options/funcionalities',
      name: 'Funcionality',
      component: () => import('@/views/funcionality/FunctionalityList.vue')
    },
    {
      path: 'funcionality_options/funcionalities/:id/:action',
      name: 'FuncionalityForm',
      component: () => import('@/views/funcionality/FunctionalityForm'),
      meta: {
        title: ':action de Funcionality :id'
      },
    },

    {
      path: 'users',
      name: 'UserList',
      component: () => import('@/views/user/UserListViews')
    },

    {
      path: 'usersForm/:id/:action',
      name: 'UserForm',
      component: () => import('@/views/user/UserFormViews'),
      meta: {
        title: ':action de user :id'
      },
    },

    {
      path: 'default_courses',
      name: 'DefaultCourses',
      component: () => import('@/components/layouts/BlackLayout.vue'),
      children: [
        {
          path: '',
          name: 'DefaultCourseList',
          component: () => import('@/views/default_courses'),
          meta: {
            title: 'Listado de cursos'
          },
        },
        {
          path: ':method/:id',
          name: 'DefaultCourseForm',
          component: () => import('@/views/default_courses/form.vue'),
          meta: {
            title: ':method de Curso :id'
          },
        }
      ]
    },

    {
      path: 'default_subjects',
      name: 'DefaultSubjects',
      component: () => import('@/components/layouts/BlackLayout.vue'),
      children: [
        {
          path: '',
          name: 'DefaultSubjectList',
          component: () => import('@/views/default_subjects'),
          meta: {
            title: 'Listado de Asignaturas'
          },
        },
        {
          path: ':method/:id',
          name: 'DefaultSubjectForm',
          component: () => import('@/views/default_subjects/form.vue'),
          meta: {
            title: ':method de Asignatura :id'
          },
        }
      ]
    },

  ]
}
